import React, { useState, Suspense } from "react";
import "./index.scss";
import css from "./index.module.scss";

import { Layout, Menu, Spin, Dropdown, Breadcrumb, Modal, Button } from "antd";
import {
  UserOutlined,
  PoweroffOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
} from "@ant-design/icons";
import { Outlet, useNavigate } from "react-router-dom";
import router from "../../router";
import { useSelector, useDispatch } from "react-redux";
import {
  deleteListItem,
  deleteListItemWithIgnore,
} from "../../store/features/historySlice";
import PwdModal from './pwdmodal'
// import Userinfo from './header'
// import * as api from '../../api'
// import {getToken} from '../../utils/auth'

const { Content, Sider } = Layout;
// const { SubMenu } = Menu;
let checkSub = [],
  checkMenu = [],
  tempMenu = [],
  checktitle = [],
  temptitle = [];

function searchMenu(menu = { children: [] }, isFirst = false) {
  if (isFirst) {
    tempMenu = [];
    temptitle = [];
  }
  let thebool = false;
  for (let i = 0; i < menu.children.length; i++) {
    let item = menu.children[i];
    if (item.children) {
      let bool = searchMenu(item);
      thebool = bool;
      if (thebool) {
        tempMenu = [...tempMenu, item.id];
        temptitle = [...temptitle, item.title];
        checkMenu = tempMenu;
        checktitle = temptitle;
        return thebool;
      }
    } else {
      if (item.path === window.location.pathname) {
        checkSub = [item.id];
        tempMenu = [...tempMenu, item.id];
        temptitle = [...temptitle, item.title];
        checkMenu = tempMenu;
        checktitle = temptitle;
        return true;
      }
    }
  }
  return thebool;
}

export default function AppLayout(props) {
  const permList = useSelector(state => state.userInfo.permList);
  searchMenu(router[0], true);
  // const [collapsed,setCollapsed]=useState(false);
  const [title, setTitle] = useState(checktitle.reverse());
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [collapsed, setCollapsed] = useState(false);
  const [selected, setSelected] = useState(checkMenu);
  // const [routes,setRoutes]=useState([]);
  const MenuList = createMenu(router[0].children);
  const routeList = getMenuList(MenuList);
  const navigate = useNavigate();

  const history = useSelector((state) => state.history.historyList);
  const userInfos = useSelector(state => state.userInfo.user);
  const dispatch = useDispatch();

  // function onCollapse(collapsed){
  //   setCollapsed(collapsed=>!collapsed);
  // };
  // async function getRoutes(){
  //   const result=await api.getRouter();
  //   setRoutes(JSON.parse(result.data));
  // }

  // useEffect(() => {
  //   dispatch(getInfoByToken());
  // }, []);

  function change(val) {
    const pos = routeList.findIndex((el) => el.key === val.key);
    if (pos > -1) {
      // dispatch(add({key:routeList[pos].path,value:undefined}));
      navigate(routeList[pos].path);
    }
    searchMenu(router[0], true);
    setTitle((title) => checktitle.reverse());
    setSelected(checkMenu);
  }
  function createMenu(menu = []) {
    let list = [];
    menu.forEach((item) => {
      // if (
      //   userPerms.findIndex((el) => Number(el.menuId) === Number(item.id)) < 0
      // ) {
      //   return;
      // }
      if (!permList.includes(item.id) && item.auth !== true) {
        return;
      }
      if (item.children) {
        list.push({
          key: item.id,
          label: item.title,
          icon: item.icon,
          path: item.path,
          visible: 1,
          children: createMenu(item.children),
        });
      } else {
        list.push({
          key: item.id,
          label: item.title,
          icon: item.icon,
          path: item.path,
          visible: 1,
        });
      }
    });
    return list;
  }
  function getMenuList(menu) {
    let list = [];
    menu.forEach((item) => {
      if (item.children) {
        const temp = getMenuList(item.children);
        list = [...list, ...temp, item];
      } else {
        list.push(item);
      }
    });
    return list;
  }
  function onOpenChange(keys) {
    setSelected(keys);
  }
  function closeHistory(e, path) {
    const index = history.findIndex((e) => e.key === path);
    if (path !== window.location.pathname) {
      dispatch(deleteListItem({ key: path, index }));
      return;
    }
    let target = "/";
    target = history[index + 1]?.key
      ? history[index + 1]?.key
      : history[0]?.key
        ? history[0]?.key
        : "/";
    dispatch(deleteListItemWithIgnore({ key: path, index }));
    navigate(target, { state: { mode: 1 } });
    searchMenu(router[0], true);
    setTitle((title) => checktitle.reverse());
    setSelected(checkMenu);
  }
  function goTo(path) {
    if (window.location.pathname === path) {
      return;
    }
    navigate(path, { state: { mode: 1 } });
    searchMenu(router[0], true);
    setTitle((title) => checktitle.reverse());
    setSelected(checkMenu);
  }
  const items = [
    {
      id: 1,
      label: '修改密码',
      onClick: () => setIsModalVisible(true)
    }
  ]
  const logout = () => {
    localStorage.removeItem("userToken");
    window.history.replaceState(null, null, "/login");
    window.location.reload();
  };

  return (
    <div style={{ width: "100%", height: "100%", background: "#e6e6e6" }}>
      <div className={css.header} style={{ padding: 0 }}>
        <div className={css.logo}></div>
        <div className={css.title}>| 轻松收</div>
        <div className={css.userbox}>
          <Dropdown menu={{ items }}>
            <div style={{ borderRight: '1px solid #fff', maxWidth: '400px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}><UserOutlined />{userInfos ? userInfos.userName : ''}</div>
          </Dropdown>
          <div onClick={logout}><PoweroffOutlined />退出</div>
        </div>
      </div>
      <Layout className={css.botlayout}>
        <Sider
          theme="light"
          style={{
            overflow: "auto",
            height: "100%",
            padding: "20px 0 15px 0",
            borderRadius: "0 5px 0 0",
          }}
          collapsed={collapsed}
        >
          <div className="logo" />
          <Menu
            theme="light"
            style={{ background: "none" }}
            selectedKeys={checkSub}
            onOpenChange={onOpenChange}
            openKeys={selected}
            mode="inline"
            onClick={change}
            items={MenuList}
          ></Menu>
        </Sider>
        <Content className={css.content}>
          <div style={{ height: 40, display: "flex", alignItems: "center", }}>
            {/* <div style={{ flex: "1" }}>
              {history.map((e) => {
                return (
                  <Tag
                    key={e.key}
                    className={`${css.Tag} ${e.key === window.location.pathname ? css.TagSelected : ""
                      }`}
                    closable={history.length > 1}
                    onClose={(event) => {
                      closeHistory(event, e.key);
                    }}
                    onClick={() => {
                      goTo(e.key);
                    }}
                    closeIcon={<CloseOutlined className={css.closeicon} />}
                  >
                    {e.name}
                  </Tag>
                );
              })}
            </div> */}
            <Button
              type="text"
              icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
              onClick={() => setCollapsed(!collapsed)}
              style={{
                fontSize: '16px',
                width: 40,
                height: 40,
                color: '#888'
              }}
            />
            <div style={{ flex: "1" }}></div>
            <Breadcrumb
              style={{ margin: "0 10px", fontSize: "12px" }}
              separator=">"
            >
              {title.map((e, i) => {
                return <Breadcrumb.Item key={i}>{e}</Breadcrumb.Item>;
              })}
            </Breadcrumb>
          </div>
          <div
            className="site-layout-background"
            style={{ padding: 20, flex: "1", overflowY: "auto" }}
          >
            <Suspense
              fallback={
                <div
                  style={{
                    textAlign: "center",
                    width: "100%",
                    marginTop: "100px",
                  }}
                >
                  <Spin size="large" />
                </div>
              }
            >
              <Outlet />
            </Suspense>
          </div>
        </Content>
        <Modal title="修改密码" open={isModalVisible} onCancel={() => { setIsModalVisible(false) }}
          bodyStyle={{ maxHeight: '700px', overflowY: 'auto' }}
          footer={null}
          style={{ top: '50px' }}
          destroyOnClose={true}
        >
          <PwdModal close={() => { setIsModalVisible(false); logout(); }}></PwdModal>
        </Modal>
      </Layout>
    </div>
  );
}
