//写一个404组件
import React from 'react';
import { Result, Button } from 'antd';
import { useNavigate } from 'react-router-dom';

function Index() {
    const navigate = useNavigate();
    return (
        <div>
            <Result
                status="404"
                title="404"
                subTitle="对不起，该页面不存在或您没有权限访问该页面"
                extra={<Button type="primary" onClick={() => navigate('/home/display/index')}>返回首页</Button>}
            />
        </div>
    )
}

export default React.memo(Index);